div {
    padding: 0px 0px 0px 0px;
  }
.col {
    text-align: center;
}
.custom-bar {
    background-color: hsl(41, 85%, 35%) !important;
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efb73d", endColorstr="#a5750d");
    background-image: -khtml-gradient(linear, left top, left bottom, from(#efb73d), to(#a5750d));
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #efb73d), color-stop(100%, #a5750d));
    background-image: -webkit-linear-gradient(top, #efb73d, #a5750d);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#efb73d), to(#a5750d));
    background-image: -webkit-linear-gradient(#efb73d, #a5750d);
    background-image: linear-gradient(#efb73d, #a5750d);
    border-color: #a5750d #a5750d hsl(41, 85%, 29%);
    color: #fff !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.39);
    -webkit-font-smoothing: antialiased;
}

.custom-center {
    margin: auto;
}
.right-box {
  max-width: 500px;
  padding: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: white;
  opacity: 0.9
}

.left-box {
  max-width: 750px;
  padding: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: white;
  opacity: 0.9
}

.right-no-white-box {
  max-width: 500px;
  padding: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  opacity: 0.9
}

.left-no-white-box {
  color: white;
  max-width: 750px;
  padding: 16px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  opacity: 0.9
}

.accordion {
  width: 100%;
}

.accordion-box {
  width: 475px;
}

.box-height {
  max-height: 275px;
}

@media only screen and (min-width: 900px) {
  .messageBox {
    width: 808px;
  }
}

@media only screen and (max-width: 1200px) {
  .formBackground {
    max-width: 100%;
  }
  .messageBox {
    max-width: 100%;
  }
}

.list-house-group {
  list-style-image: url("/img/house-icon.jpg");
}

.list-group {
  list-style-image: url("/img/checkmark.png")
}

.list-green-check-group {
  list-style-image: url("/img/green-check.jpg");
}

.list-red-x-group {
  list-style-image: url("/img/red-x.jpg");
}

.custom-box {
  background-color: hsl(41, 85%, 35%) !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efb73d", endColorstr="#a5750d");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#efb73d), to(#a5750d));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #efb73d), color-stop(100%, #a5750d));
  background-image: -webkit-linear-gradient(top, #efb73d, #a5750d);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#efb73d), to(#a5750d));
  background-image: -webkit-linear-gradient(#efb73d, #a5750d);
  background-image: linear-gradient(#efb73d, #a5750d);
  border-color: #a5750d #a5750d hsl(41, 85%, 29%);
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.39);
  -webkit-font-smoothing: antialiased;
}
.top-jumbotron {
  background-image: url(/img/house-1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5em inherit;
}

.bottom-jumbotron {
  background-image: url(/img/house-3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5em inherit;
}

/* Spacing between the boxes */
.box {
  margin: 10px 10px 10px 10px;
}

/* Add styles to the form container */
.formBackground {
  border: 2px solid lightgray;
  max-width: 500px;
  padding: 16px;
  background-color: white;
  opacity: 0.9
}

/* Add styles to the form container */
.messageBox {
  border: 2px solid lightgray;
  max-width: 500px;
  padding: 16px;
  background-color: white;
  opacity: 0.9
}

.submittedBox {
  border: 2px solid rgb(146, 207, 146);
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

label {
  clear:left;
  text-align:right;
  padding-left:5px;
}

@media only screen and (min-width: 900px) {

  .messageBox {
    width: 808px;
  }
}

@media only screen and (max-width: 1200px) {

  .formBackground {
    max-width: 100%;
  }
  .messageBox {
    max-width: 100%;
  }
}

.list-group {
  list-style-image: url("/img/checkmark.png")
}

.btn-custom {
  background-color: hsl(41, 85%, 35%) !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efb73d", endColorstr="#a5750d");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#efb73d), to(#a5750d));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #efb73d), color-stop(100%, #a5750d));
  background-image: -webkit-linear-gradient(top, #efb73d, #a5750d);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#efb73d), to(#a5750d));
  background-image: -webkit-linear-gradient(#efb73d, #a5750d);
  background-image: linear-gradient(#efb73d, #a5750d);
  border-color: #a5750d #a5750d hsl(41, 85%, 29%);
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.39);
  -webkit-font-smoothing: antialiased;
}

html, body, #root {
  padding: 0;
  margin: 0;
  height: 100%;
}
